<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>Back Up Mode</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" small text to="/gpm/backup_mode">
          <v-icon left dark>mdi-keyboard-backspace</v-icon>Back
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <!-- editor -->
        <editor
          :initial="dataItem"
          :docCreate="docCreate"
          @data="save"
        ></editor>
        <!-- end -->
        <snackbar ref="snackbar"></snackbar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Editor from "../_components/editor.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Editor,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  data: () => ({
    dataItem: {},
    docCreate: false,
  }),
  watch: {
    "$route.params.id": {
      handler: "getModeData",
      immediate: true,
    },
  },
  methods: {
    getModeData(id) {
      const self = this;
      this.$store
        .dispatch("get", `/back_up_mode/${id}`)
        .then((res) => {
          console.log(res);
          self.dataItem = res.ResponseData;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    save(data) {
      const url = `/back_up_mode/${data.id}`;
      const self = this;
      this.$store
        .dispatch("put", { url, data })
        .then((res) => {
          console.log(res);
          self.$refs.snackbar.show(res, "green");
          if (res.ResultCode == 1200) {
            this.$store.commit("loader", false);
            self.$router.push(`/gpm/backup_mode`);
          } else {
            this.$store.commit("loader", false);
            self.$refs.snackbar.show(res.ResultDesc, "red");
            self.loading = false;
          }
        })
        .catch((err) => {
          this.$store.commit("loader", false);
          this.$refs.snackbar.show(err.response.data.errors, "red");
          self.loading = false;
        });
    },
  },
};
</script>